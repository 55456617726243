@import "_variables.scss";


@mixin hook-button {
      border-radius: var(--border-radius-large)!important;
    font-size: var(--font-m)!important;
    padding-top: 0!important;
    padding-bottom: 0!important;
    height: 35px!important;
    line-height: 35px;
    font-weight: bold!important;
    min-width: 200px!important;

    @media (min-width: 768px) {
        height: 40px!important;
        line-height: 40px;
        min-width: 210px!important;
    }

    &.uk-button-primary {
        background-color: var(--global-link-color);
        transition: background-color 150ms, color 150ms, border-color 150ms;

        &:hover {
            background-color: var(--global-link-hover-color);
        }
    }

    &.uk-button-alt {
        background-image: linear-gradient(101deg, var(--global-warning-background-light) 15%, var(--global-warning-background) 129%),
                          linear-gradient(101deg, var(--global-warning-background-light) 15%, var(--global-warning-background) 129%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        transition: opacity 150ms;

        &:hover {
            opacity: 0.8;
            background-image:
                    linear-gradient(101deg, var(--global-warning-background-light) 15%, var(--global-warning-background) 129%),
                    linear-gradient(101deg, var(--global-warning-background-light) 15%, var(--global-warning-background) 129%);

        }
    }
}

// this doesnt actually apply to form.
// it applies to inputs. thanks ui kit!
@mixin hook-form {
    border: 1px solid var(--global-color);
    border-radius: var(--border-radius-small);
    font-size: var(--font-m);
    color: var(--global-color);


}




@mixin hook-form-label {
    font-weight: 600;
    font-size: 13px;

    @media screen and (min-width:768px) {
        font-size: 14px;
    }
}
// cant hook into uk icon flip for RTL. however, one should still think of this line as a "hook"
.uk-form-icon-flip ~ .uk-input {
    padding-left: 0!important;
    padding-inline-end: 40px !important;
}

// form error fix
.input-inner-wrapper {
    z-index: 2;
}
@mixin hook-form-danger {
    position: relative;
    font-weight: bold;
    border-color: var(--global-danger-color);
    color: var(--global-danger-color);
    z-index: 2;
}

.uk-input {
    ::-webkit-input-placeholder,
    ::placeholder {
        opacity: 0.8;
        border-color : var(--global-color);
        color: var(--global-color);
        font-weight  : bold;
    }
}
.input-wrapper-error .uk-form-error-message {
    position: relative;
    display: block;
    background: var(--global-danger-background);
    color: var(--global-color)!important;
    padding: 7px 8px 3px 8px;
    top: -6px;
    z-index: 0;
    border-radius: 0 0 var(--border-radius-small) var(--border-radius-small);
    font-size: 10px;

    @media screen and (min-width: 767px) {
        font-size: 12px;
    }
}
@mixin hook-card {
      border-radius: var(--border-radius-large);

    &.no-radius {
        border-radius: 0;
    }

    &.uk-card-muted {
        background: var(--global-muted-background);
        color: var(--global-color);
        border: 1px solid var(--global-medium-gray);
    }

    .uk-card-muted .uk-card-title {
        color: var(--global-color);
    }


}

@mixin hook-modal {
    font-size: var(--font-m);
    padding: 120px 35px 35px 35px!important;



    .uk-modal-dialog {
        border-radius: var(--border-radius-medium);

        @media (max-width: 1200px) {
            max-width: 500px!important;
            margin-left: auto;
            margin-right: auto;
        }

        @media (max-width: 767px) {
            max-width: 290px!important;
            margin-left: auto;
            margin-right: auto;
        }

        @media (max-width: 359px) {
            max-width: 240px!important;
            margin-left: auto;
            margin-right: auto;
        }


    }

    .uk-close {
        color: var(--global-color);
        right: auto;
        left: auto;
        inset-inline-end: 10px;

        svg {
            height: 13px;
            width: 13px;
        }
    }

    .uk-modal-header {
        border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
        border-bottom: none;
        text-align: center;
        font-size: var(--font-l);
        padding-top: 30px;
        padding-bottom: 0;

    }

    .uk-modal-body {
        padding-top: 30px;
        padding-bottom: 12px;
    }

    .uk-modal-footer {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        border-top: none;
        border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium);
        padding-bottom: 30px;
        gap: 12px;
        text-align: center;

        .footer-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            border-top: none;
            border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium);
            gap: 12px;
            text-align: center;
        }
    }
}

@mixin hook-notification-message-danger {
    color: var(--global-danger-color);
    background-color: var( --global-danger-background);
}
@mixin hook-alert {
    padding: 15px 35px 15px 35px;

    .uk-close {
        right: auto;
        left: auto;
        inset-inline-end: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
}
@mixin hook-alert-danger {
    background-color: var(--global-danger-background);
    color: var(--global-danger-color);
    border-radius: var(--border-radius-medium);
}


@mixin hook-table-header-cell {
    font-weight: 600;
    font-size: calc($global-font-size * 1.1);
}
